import React, { useEffect, useRef, useState } from 'react';
import CurrencyFormatter from '../Static/CurrencyFormatter';
import { Table } from 'react-bootstrap';
import AccountTransferIcon from "../../assets/images/icons/AccountTransferIcon.svg";
import AccountTransferCrossIcon from "../../assets/images/icons/AccountTransferCrossIcon.svg";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import ShippingCrossIcon from "../../assets/images/icons/LocalShippingCrossIcon.svg";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { CommonService } from '../../Service/CommonService';
import EditIcon from '../../assets/images/icons/EditIcon.svg';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { VisibilityOutlined, EditOutlined, DeleteOutlined } from "@mui/icons-material";
import StatusDropdown from "../Common/StatusDropdown";
import { OrderApiService } from '../../Service/OrderApiService';
import { Skeleton, Tooltip, Typography } from '@mui/material';
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import Offcanvas from 'react-bootstrap/Offcanvas';
import ViewProductDetail from './ViewProductDetail';
import { useLocation, useParams } from 'react-router';
import Constant from '../../Constant';
import Customspinner from '../Common/CustomSpinner';
import BarrelSelectionPanel from './BarrelSelectionPanel';
import AddSharpIcon from "@mui/icons-material/AddSharp";
import WarningIcon from '@mui/icons-material/Warning';
import { InventoryApiService } from '../../Service/InventoryApiService';

interface CartProps {
    cartItems: any[];
    onEditClick: (itemId: string) => void;
    onDeleteClick: (itemId: string) => void;
    onOrderItemStatusChange:(newStatus: string, orderItemId: any, inventoryId: any, inventoryTitle: any) => void;
    viewDetails: any;
    handleViewClose: any;
    handleViewShow: any;
    isNotEnoughBarrels: any[];
    isNotSelectedRequiredBarrel: any[];
    clearArrays; // Receive the function to clear arrays
}

const InventoryFileListTable: React.FC<CartProps> = ({ cartItems, onEditClick, onDeleteClick, onOrderItemStatusChange, viewDetails, handleViewClose, handleViewShow, isNotEnoughBarrels, isNotSelectedRequiredBarrel, clearArrays }) => {
    const location = useLocation();
    const currentPath = location.pathname;
    const { id } = useParams<{ id: string }>();
    const [isOpen, setIsOpen] = useState<boolean[]>();
    const [inventoryId, setinventoryId] = useState("");
    const [isCurrentStatusPendingOrUnderReview, setIsCurrentStatusPendingOrUnderReview] = useState<boolean>(false);
    const [isSeller, setIsSeller] = useState<boolean>(false);
    const [orderSummary, setOrderSummary] = React.useState<any>([]);
    const [isOrderItemStatusActionRequired, setIsOrderItemStatusActionRequired] = useState<boolean>(false);
    const [viewBarrelSelection, setViewBarrelSelection] = useState(false);
    const [totalAvailableBarrelsCount, setTotalAvailableBarrelsCount] = React.useState<any>([]);
    const [totalReservedBarrelsCount, setTotalReservedBarrelCount] = React.useState<any>([]);
    const [selectedOrderItem, setSelectedOrderItem] = useState<any>(null);
    const [selectedOrderItemIndex, setSelectedOrderItemIndex] = useState<number | null>(null);
    const [isSpinner, setIsSpinner] = useState(false);
    let dashboardPrefrence="";
    // For Setting Equal width 
    const priceRef = useRef<HTMLTableCellElement>(null);
    const totalPriceRef = useRef<HTMLTableCellElement>(null);
    const handlingRef = useRef<HTMLTableCellElement>(null);
    const servicesRef = useRef<HTMLTableCellElement>(null);
    const ownerStatusRef = useRef<HTMLTableCellElement>(null);
    const actionRef = useRef<HTMLTableCellElement>(null);
    const platformFeesRef = useRef<HTMLTableCellElement>(null);
    

  const [columnWidths, setColumnWidths] = useState<{
    priceWidth: number;
    totalPriceWidth: number;
    handlingWidth: number;
    servicesWidth: number;
    ownerStatusWidth: number;
    actionWidth: number;
    platformFeesWidth: number;
  }>({
    priceWidth: 0,
    totalPriceWidth: 0,
    handlingWidth: 0,
    servicesWidth: 0,
    ownerStatusWidth: 0,
    actionWidth: 0,
    platformFeesWidth: 0,
  });

    useEffect(() => {
        const measureWidths = () => {
        setColumnWidths({
            priceWidth: priceRef.current?.offsetWidth || 0,
            totalPriceWidth: totalPriceRef.current?.offsetWidth || 0,
            handlingWidth: handlingRef.current?.offsetWidth || 0,
            servicesWidth: servicesRef.current?.offsetWidth || 0,
            ownerStatusWidth: ownerStatusRef.current?.offsetWidth || 0,
            actionWidth: actionRef.current?.offsetWidth || 0,
            platformFeesWidth: platformFeesRef.current?.offsetWidth || 0,
        });
        };

        // Use ResizeObserver to track size changes
        const resizeObserver = new ResizeObserver(() => {
        measureWidths();
        });

        // Observe each reference
        const refs = [
        priceRef,
        totalPriceRef,
        handlingRef,
        servicesRef,
        ownerStatusRef,
        actionRef,
        platformFeesRef,
        ];

        refs.forEach((ref) => {
        if (ref.current) {
            resizeObserver.observe(ref.current);
        }
        });

        // Measure widths initially
        measureWidths();

        // Cleanup observer on unmount
        return () => {
        resizeObserver.disconnect();
        };
    }, []);
  

    useEffect(() => {
        setIsOpen(cartItems.map(() => false));

        const isSeller = cartItems.some((item) => {
            return item.typeSenseResponse.company_id === CommonService.getCompanyId() && CommonService.isUser()
        });
        setIsSeller(isSeller);

        const currentStatus = cartItems.some((item) => {
            return item.orderStatus === "PENDING_REVIEW" || item.orderStatus === "UNDER_REVIEW";
        });
        setIsCurrentStatusPendingOrUnderReview(currentStatus);

        const orderItemStatus = cartItems.some((item) => {
            return !item.isOrderItemDeleted && item.ownerOrderStatus === "ACTION_REQUIRED"
                && (CommonService.getRole() === Constant.Role.ADMIN && CommonService.isSameCompany(item?.companyId)
                    || CommonService.isSameUser(item.createdBy)
                ); 
        });
        setIsOrderItemStatusActionRequired(orderItemStatus);

        // Handle admin-specific data fetching
        const fetchBarrelCount = async () => {
            if (CommonService.isAdmin()) {
                setIsSpinner(true);

                // Initialize state with 0s for each cart item
                setTotalAvailableBarrelsCount(new Array(cartItems.length).fill(0));
                setTotalReservedBarrelCount(new Array(cartItems.length).fill(0));

                // Wait for all fetches to complete before hiding the spinner
                const fetchPromises = cartItems.map(async (item, index) => {
                    // Fetch available barrels count for each item
                    fetchTotalAvailableBarrelCount(item?.inventoryId, index);

                    // Fetch reserved barrels count if the order is not in "PENDING_REVIEW" or "UNDER_REVIEW" status
                    const isNotReviewStatus = item?.orderStatus !== "PENDING_REVIEW" && item?.orderStatus !== "UNDER_REVIEW";
                    if (isNotReviewStatus) {
                        fetchTotalReservedBarrelCount(item?.orderId, item?.id, index);
                    }
                });

                // Wait for all fetch promises to resolve
                await Promise.all(fetchPromises);

                // Set spinner to false after all async operations are done
                setIsSpinner(false);
            }
        };

        // Call the fetchData function
        fetchBarrelCount();
        fetchOrderSummaryDetail();
    }, [cartItems]);

    const toggleSection = (index: number) => {
        setIsOpen(prevState => prevState.map((open, i) => (i === index ? !open : open)));
    };

    //formate the ownerOrderStatus
    function formatStatus(ownerOrderStatus?: string): string {
        let formattedStatus = "";
        if (ownerOrderStatus !== undefined) {
        formattedStatus = ownerOrderStatus
            ?.split(/[_\\]/)
            .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(" ");
        }
        return formattedStatus;
    }

    const fetchOrderSummaryDetail = async () => {

        if (currentPath.startsWith('/user/view-as-buyer/order-detail'))
          dashboardPrefrence = Constant.Profile.BUYER;
        else if (currentPath.startsWith('/user/view-as-owner/order-detail'))
          dashboardPrefrence = Constant.Profile.OWNER;
        else
          dashboardPrefrence= CommonService.getDashboardPrefrence();
    
        const response: any = await OrderApiService.getOrderSummaryByOrderId(id, dashboardPrefrence);
        if (response.data) setOrderSummary(response.data);
    };

    const handleShowBarrelSelection = (orderItem: any, index) => {
        setViewBarrelSelection(true);
        setSelectedOrderItem(orderItem);
        setSelectedOrderItemIndex(index);
    }
    
    const handleCloseBarrelSelection = () => {
        setViewBarrelSelection(false);
        clearArrays(); 
        fetchTotalAvailableBarrelCount(selectedOrderItem?.inventoryId, selectedOrderItemIndex);
        fetchTotalReservedBarrelCount(selectedOrderItem?.orderId, selectedOrderItem?.id, selectedOrderItemIndex);
    }

    // Fetches the total available barrel count for a given group
    const fetchTotalAvailableBarrelCount = async (inventoryId: string, index: number) => {
        try {
            const response = await InventoryApiService.getAllBarrelsCount(inventoryId);
            setTotalAvailableBarrelsCount((prevCounts: any[]) => {
                const newCounts = [...prevCounts];
                newCounts[index] = response?.data; // Update count for specific item index
                return newCounts;
            });
        } catch (error) {
            console.error('Error fetching available barrels count:', error);
        }
    };

    // Fetches the total reserved barrel count for a specific order item
    const fetchTotalReservedBarrelCount = async (orderId: any, orderItemId: any, index: number) => {
        try {
            const response = await OrderApiService.getOrderReservedBarrelsCount(orderId, orderItemId);
            setTotalReservedBarrelCount((prevCounts: any[]) => {
                const newCounts = [...prevCounts];
                newCounts[index] = response?.data; // Update count for specific item index
                return newCounts;
            });
        } catch (error) {
            console.error('Error fetching reserved barrels count:', error);
        }
    };

    return (
        <>
        <div className="table-responsive-lg table-responsive-fixed-header">
                <Table className='orderRequest-inv-table'>
                <thead>
                <tr>
                    <th className='text-nowrap text-start'>Inventory</th>
                    { !isSeller && <>
                    <th colSpan={2} className="text-center text-nowrap">Seller</th>
                    <th colSpan={2} className="text-center text-nowrap">Buyer</th>
                    </>}
                    <th className='text-nowrap'>Quantity</th>
                    <th  ref={priceRef} className='text-nowrap'>Price</th>
                    <th ref={totalPriceRef} className='text-nowrap'>Total Price</th>
                    {isSeller && <>
                    <th className='text-nowrap text-center' ref={platformFeesRef}>Fee</th>
                    <th className='text-nowrap text-center' ref={platformFeesRef}>Total Fee</th>
                    </>}
                    { !isSeller && <th colSpan={2} className="text-center text-nowrap">Fees</th>}
                    { (isSeller || CommonService.isAdmin()) && <th className='text-nowrap text-center' ref={ownerStatusRef}>Owner Status</th> }
                    {(isOrderItemStatusActionRequired || (CommonService.isAdmin() && isCurrentStatusPendingOrUnderReview)) && <th className='text-nowrap text-center' ref={actionRef}> Action</th> }
                </tr>
                <tr>
                    <th className='text-nowrap text-center'></th>
                    { !isSeller && <>
                    <th className='text-nowrap text-center'>Relocation</th>
                    <th className='text-nowrap text-center'>Cha. of Acc.</th>
                    <th className='text-nowrap text-center'>Relocation</th>
                    <th className='text-nowrap text-center'>Cha. of Acc.</th>
                    </>}
                    <th className='text-nowrap text-center'></th>
                    <th className='text-nowrap text-center'></th>
                    <th className='text-nowrap text-center'></th>
                    {isSeller && <>
                    <th className='text-nowrap text-center'></th>
                    <th className='text-nowrap text-center'></th>
                    </>}
                    { !isSeller && <>
                    <th className='text-nowrap' ref={handlingRef}>Handling</th>
                    <th className='text-nowrap text-center' ref={servicesRef}>Services</th>
                    </>}
                    {(isSeller || CommonService.isAdmin()) && <th className='text-nowrap text-center'></th>}
                    {(isOrderItemStatusActionRequired || (CommonService.isAdmin() && isCurrentStatusPendingOrUnderReview)) &&<th className='text-nowrap text-center'></th>}
                </tr>
                </thead>
                {/* {(!cartItems || cartItems.length == 0) ? (
                  <div style={{ height: "236px", display: "flex", alignItems: "center", justifyContent: "center", textAlign: 'center' }}>
                    <Customspinner />
                  </div>
                ):(     */}
                <tbody>
                 
                    <>
                    {cartItems.length > 0 ? (
                        cartItems.map((item: any, index: number) => (
                            <React.Fragment key={index}>
                                <tr className={`${item?.isOrderItemDeleted ? "deleted-item-row" : ""}`}>
                                    <td className='text-start cursor-pointer' onClick={() => {handleViewShow(); setinventoryId(item.inventoryId); }}>
                                        <div className="header d-flex flex-column" onClick={() => toggleSection(index)}>
                                            {/* <div className={`icon ${isOpen[index] ? 'minus' : 'plus'}`}></div> */}
                                            {/* <>
                                                {isOpen[index] ? <RemoveCircleOutlineIcon sx={{ color: "#737376", mr: 1, cursor: 'pointer' }} /> : <AddCircleOutlineIcon sx={{ color: "#737376", mr: 1, cursor: 'pointer' }} />}
                                            </> */}
                                            <span className="inventory-name"><p className='m-1'>{item.inventoryTitle}</p></span>
                                            <Typography component="p" sx={{ color: "#737376", fontSize: "13px", fontWeight: "400", lineHeight:"21.28px" }}>
                                                {CommonService.isAdmin() && item.inventoryOwnerCompanyName}
                                            </Typography>

                                        <span className='removed-text'>Removed</span>
                                        </div>
                                    </td>
                                    { !isSeller && <>
                                    <td className='text-center'>
                                        {item.isRelocationRequiredByOwner ? (
                                            <>
                                                <CheckIcon fontSize="small" sx={{ color: '#25CD25' }} /> Yes
                                            </>
                                        ) : (
                                            <>
                                                <CloseIcon fontSize="small" sx={{ color: '#FF3B30' }} /> No
                                            </>
                                        )}
                                    </td>
                                    <td className='text-center'>
                                        {item.isAccountTransferByOwner ? (
                                            <>
                                               <CheckIcon fontSize="small" sx={{ color: '#25CD25' }} /> Yes
                                           </>
                                        ) : (
                                           <>
                                               <CloseIcon fontSize="small" sx={{ color: '#FF3B30' }} /> No
                                           </>
                                        )}
                                    </td>
                                    <td className='text-center'>
                                        {item.isRelocationRequiredByBuyer ? (
                                            <>
                                                <CheckIcon fontSize="small" sx={{ color: '#25CD25' }} /> Yes
                                            </>
                                        ) : (
                                            <>
                                                <CloseIcon fontSize="small" sx={{ color: '#FF3B30' }} /> No
                                            </>
                                        )}
                                    </td>
                                    <td className='text-center'>
                                        {item.isAccountTransferByBuyer ? (
                                            <>
                                               <CheckIcon fontSize="small" sx={{ color: '#25CD25' }} /> Yes
                                           </>
                                        ) : (
                                           <>
                                               <CloseIcon fontSize="small" sx={{ color: '#FF3B30' }} /> No
                                           </>
                                        )}
                                    </td>
                                    </>}
                                    {item?.isOrderItemDeleted && <td className='pb-4'>{item.quantity} barrels</td>}
                                    {!item?.isOrderItemDeleted && (
                                        <td className={`${!(totalAvailableBarrelsCount[index] < item?.quantity) && "pb-4"}`}>
                                            <div className="position-relative">
                                                {item?.quantity} barrels
                                                <>
                                                    {CommonService.isAdmin() && !isSpinner && !['REJECTED', 'CLOSED', 'IN_TRANSIT', 'OWNERSHIP_TRANSFERRED'].includes(item?.orderStatus) && (
                                                        <Tooltip
                                                            arrow
                                                            title={`${Intl.NumberFormat().format(totalAvailableBarrelsCount[index] + totalReservedBarrelsCount[index])} ${(totalAvailableBarrelsCount[index] + totalReservedBarrelsCount[index]) > 1 ? 'Barrels' : 'Barrel'} in Stock`}
                                                        >
                                                            <span
                                                                className="available-barrels-count ms-2"
                                                                style={{
                                                                    backgroundColor: (totalAvailableBarrelsCount[index] + totalReservedBarrelsCount[index]) < item?.quantity && totalReservedBarrelsCount[index] !== item?.quantity ? "#C7858B" : "#75BC84"
                                                                }}
                                                            >
                                                                {(totalAvailableBarrelsCount[index] + totalReservedBarrelsCount[index]) >= 10000 ? "9,999+" : Intl.NumberFormat().format(totalAvailableBarrelsCount[index] + totalReservedBarrelsCount[index])}
                                                            </span>
                                                        </Tooltip>
                                                    )}

                                                    {CommonService.isAdmin() && !isSpinner && !['REJECTED', 'PENDING_REVIEW', 'UNDER_REVIEW'].includes(item?.orderStatus) && (
                                                        <div>
                                                            <>
                                                                {totalAvailableBarrelsCount[index] >= item?.quantity && totalReservedBarrelsCount[index] === 0 && (
                                                                    <button
                                                                        style={{ marginLeft: "-4px" }}
                                                                        onClick={() => handleShowBarrelSelection(item, index)}
                                                                        className="reserve-barrel-btn ps-0"
                                                                    >
                                                                        <AddSharpIcon /> Reserve
                                                                    </button>
                                                                )}

                                                                {totalReservedBarrelsCount[index] > 0 && (
                                                                    <button
                                                                        onClick={() => handleShowBarrelSelection(item, index)}
                                                                        className="reserve-barrel-btn ps-0"
                                                                    >
                                                                        {totalReservedBarrelsCount[index]} Reserved
                                                                    </button>
                                                                )}
                                                            </>
                                                        </div>
                                                    )}

                                                    {(totalAvailableBarrelsCount[index] + totalReservedBarrelsCount[index]) < item?.quantity && !isSpinner && isNotEnoughBarrels.includes(item?.id) && CommonService.isAdmin() && (
                                                        <span
                                                            className="barrel-error"
                                                            style={{
                                                                bottom: (totalAvailableBarrelsCount[index] > 0 && isNotEnoughBarrels.includes(item?.id)) ? "-40px" : "-25px"
                                                            }}
                                                        >
                                                            <WarningIcon /> Out of Stock, please change quantity.
                                                        </span>
                                                    )}

                                                    {(totalAvailableBarrelsCount[index] < item?.quantity || totalReservedBarrelsCount[index] < item?.quantity) && isNotSelectedRequiredBarrel.includes(item?.id) && !isSpinner && CommonService.isAdmin() && (
                                                        <span
                                                            className="barrel-error"
                                                            style={{
                                                                bottom: totalAvailableBarrelsCount[index] > 0 ? "-22px" : "-25px"
                                                            }}
                                                        >
                                                            <WarningIcon /> Please select the barrels to match the quantity
                                                        </span>
                                                    )}
                                                </>
                                            </div>
                                        </td>
                                    )}

                                    <td  ref={priceRef}><CurrencyFormatter amount={!isSeller ? item.price : 
                                        item.typeSenseResponse.fee_type == 'FLAT' 
                                        ? item.price - item.platformFee
                                        : item.price / (1 + item.platformFee / 100)} locale="en-US" currency="USD" />
                                    <p className="per-barrel-text">per barrel</p></td>
                                    <td ref={totalPriceRef}><CurrencyFormatter amount={!isSeller ? item.totalPrice : 
                                        item.typeSenseResponse.fee_type == 'FLAT' 
                                        ? (item.totalPrice  - (item.platformFee * item.quantity))
                                        : (item.totalPrice / (1 + item.platformFee / 100))} locale="en-US" currency="USD" /></td>
                                    {isSeller && <>
                                    <td className='text-center' ref={platformFeesRef}>
                                        (<CurrencyFormatter
                                            amount={
                                            item.typeSenseResponse.fee_type == 'FLAT' 
                                                ? item.platformFee
                                                : (item.price * item.platformFee) / 100
                                            }
                                            locale="en-US"
                                            currency="USD"
                                        />)
                                        <p className="per-barrel-text">per barrel</p>
                                    </td>
                                    <td className='text-center' ref={platformFeesRef}>
                                        (<CurrencyFormatter
                                            amount={
                                            item.typeSenseResponse.fee_type == 'FLAT' 
                                                ? item.platformFee * item.quantity
                                                : (item.price * item.platformFee) / 100
                                            }
                                            locale="en-US"
                                            currency="USD"
                                        />)
                                    </td>
                                    </>}
                                    { !isSeller && <>
                                    <td ref={handlingRef}><CurrencyFormatter amount={item.handlingFee} locale="en-US" currency="USD" />
                                    <p className="per-barrel-text">per barrel</p></td>
                                    <td className='text-center' ref={servicesRef}><CurrencyFormatter amount={item.serviceFee} locale="en-US" currency="USD" /></td>
                                    </>}
                                    {(isSeller || CommonService.isAdmin())  && <> 
                                    <td className='text-center' ref={ownerStatusRef}>
                                        {CommonService.isAdmin() && <StatusDropdown currentStatus={formatStatus(item?.ownerOrderStatus)}
                                            onStatusChange={(newStatus) =>
                                                onOrderItemStatusChange(newStatus,item['id'],item['inventoryId'],item['inventoryTitle'])
                                            }
                                            disableCondition={item?.isOrderItemDeleted || CommonService.isSameCompany(item?.companyId) || ["REJECTED", "ADMIN_REJECTED", "ACCEPTED"].includes(item?.ownerOrderStatus)
                                                || ["ACCEPTED", "REJECTED", "IN_PROGRESS", "CONTRACT_SENT", "CONTRACT_EXECUTED", "INVOICE_SENT", "PAYMENT_RECEIVED", "OWNERSHIP_TRANSFERRED", "IN_TRANSIT", "CLOSED", "CANCELLED"].includes(item?.orderStatus)
                                            }
                                            allowedLabels={(item?.ownerOrderStatus === "ADMIN_ACCEPTED") ? ["Reject for Owner"] 
                                                            : item?.ownerOrderStatus === "PENDING_REVIEW" ? ["Accept for Owner", "Reject for Owner"] 
                                                            :["Send to Owner", "Accept for Owner", "Reject for Owner"]}/>}

                                        {CommonService.isUser() && <StatusDropdown currentStatus={formatStatus(item?.ownerOrderStatus)}
                                            onStatusChange={(newStatus) =>
                                                onOrderItemStatusChange(newStatus,item['id'],item['inventoryId'],item['inventoryTitle'])
                                            }
                                            disableCondition={item?.isOrderItemDeleted || CommonService.isSameCompany(item?.companyId) || ["REJECTED", "ADMIN_REJECTED"].includes(item?.ownerOrderStatus)
                                                || ["ACCEPTED", "REJECTED", "IN_PROGRESS", "CONTRACT_SENT", "CONTRACT_EXECUTED", "INVOICE_SENT", "PAYMENT_RECEIVED", "OWNERSHIP_TRANSFERRED", "IN_TRANSIT", "CLOSED", "CANCELLED"].includes(item?.orderStatus)
                                            }
                                            allowedLabels={item?.ownerOrderStatus === "ADMIN_ACCEPTED" ? ["Rejected"] : ["Accepted", "Rejected"]}/>}

                                        {(item?.ownerOrderStatus === "REJECTED" || item?.ownerOrderStatus === "ADMIN_REJECTED") && (
                                            <Tooltip
                                            title={`Rejection Note: ${item.ownerOrderRejectionNote}`}
                                            arrow
                                            >
                                            <StickyNote2Icon
                                                className="note-icon cursor-pointer"
                                                style={{ color: "gray" }}
                                            />
                                            </Tooltip>
                                        )}

                                    </td>
                                    </>}
                                    {((((CommonService.isSameCompany(item?.companyId) && CommonService.getRole() === Constant.Role.ADMIN) || CommonService.isSameUser(item?.createdBy)) && item?.ownerOrderStatus ===  "ACTION_REQUIRED") || (CommonService.isAdmin() && isCurrentStatusPendingOrUnderReview))
                                        && <td className='text-center' ref={actionRef}>
                                            {!item?.isOrderItemDeleted &&
                                                <div className='d-flex justify-content-center gap-3'>
                                                    <img
                                                        src={EditIcon}
                                                        alt="EditIcon"
                                                        onClick={() => onEditClick(item.id)} 
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                    {cartItems.length > 1 && (<DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => { onDeleteClick(item.id); fetchOrderSummaryDetail();}}/>)}
                                                </div>
                                            }
                                        </td>
                                    }
                                </tr>
                                {/* <tr className="product-info position-sticky bottom-0">
                                    <td colSpan={!isSeller ? 6 : 2} className='text-nowrap text-end'>
                                        <Typography component="span" sx={{ color: "#333333", fontSize: "13px", fontWeight: "400", lineHeight: "17.29px" }}>
                                            The price does not include shipping costs.
                                        </Typography>
                                    </td>
                                    <td className='text-nowrap text-center'> <Typography
                                        component="span"
                                        sx={{ color: "#333333", fontSize: "18px", fontWeight: "700", lineHeight: "23.94px" }}
                                    >
                                        Subtotal:
                                    </Typography>
                                    </td>
                                    {isSeller && <td className='text-center'>
                                        (<CurrencyFormatter
                                            amount={(orderSummary.totalPlatformFees)}
                                            locale="en-US"
                                            currency="USD"
                                        />)
                                    </td>}
                                    <td className='text-nowrap'>
                                        <Typography
                                            component="span"
                                            sx={{ color: "#333333", fontSize: "16px", fontWeight: "600", lineHeight: "21.28px" }}
                                        >
                                            <CurrencyFormatter
                                                amount={!isSeller ? orderSummary.subTotal : orderSummary.orderTotalWithoutPlatformFees}
                                                locale="en-US"
                                                currency="USD"
                                            />
                                        </Typography>
                                    </td>
                                    {!isSeller && <>
                                        <td className='text-nowrap'>
                                            <Typography
                                                component="span"
                                                sx={{ color: "#333333", fontSize: "16px", fontWeight: "600", lineHeight: "21.28px" }}
                                            >
                                                <CurrencyFormatter
                                                    amount={orderSummary.totalHandlingFees}
                                                    locale="en-US"
                                                    currency="USD"
                                                />
                                            </Typography>
                                        </td>
                                        <td className='text-nowrap'>
                                            <Typography
                                                component="span"
                                                sx={{ color: "#333333", fontSize: "16px", fontWeight: "600", lineHeight: "21.28px" }}
                                            >
                                                <CurrencyFormatter
                                                    amount={orderSummary.totalServiceFees}
                                                    locale="en-US"
                                                    currency="USD"
                                                />
                                            </Typography>
                                        </td>
                                    </>}
                                    <td className='text-nowrap' colSpan={2}>
                                        <Typography
                                            component="span"
                                            sx={{ color: "#333333", fontSize: "18px", fontWeight: "700", lineHeight: "23.94px" }}
                                        >
                                            Total:
                                        </Typography>
                                        <Typography
                                            component="span"
                                            sx={{ color: "#333333", fontSize: "18px", fontWeight: "700", lineHeight: "23.94px", paddingLeft: "10px", marginLeft:"auto" }}
                                        >
                                            <CurrencyFormatter
                                                amount={!isSeller ? orderSummary.orderTotal : orderSummary.orderTotalWithoutPlatformFees}
                                                locale="en-US"
                                                currency="USD"
                                            />
                                        </Typography>
                                    </td>
                                </tr> */}
                            </React.Fragment>
                        ))
                    ):(
                        <tr>
                        <td colSpan={12} style={{ textAlign: 'center' }} className='border-0'>
                          <div style={{ height: "222px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Customspinner />
                          </div>
                        </td>
                      </tr>  
                    )}
                    </>
                </tbody>
            </Table>
            
        </div>
        {cartItems.length > 0 && (<div className='total-row--wrapper'>
            <div className={`d-flex justify-content-end ${(!CommonService.isAdmin() && !isSeller)? "flex-column align-items-end" : "flex-row align-items-start"}`}>
                    <div className='d-flex align-items-start'>
                        <div className='total--amount-wrapper' style={{ width: `${columnWidths.priceWidth}px` }}>
                            {isSeller ? 'Total:' : 'Subtotal:'}
                        </div>
                        <div className='amount-wrapper' style={{ width: `${isSeller ? columnWidths.platformFeesWidth : columnWidths.totalPriceWidth}px`, minWidth: 100, }}>
                            {/* {isSeller && (<CurrencyFormatter
                                amount={(orderSummary.totalPlatformFees)}
                                locale="en-US"
                                currency="USD"
                            />)} */}
                            <CurrencyFormatter
                                amount={!isSeller ? orderSummary.subTotal : orderSummary.orderTotalWithoutPlatformFees}
                                locale="en-US"
                                currency="USD"
                            />
                            <div className='total--amount-wrapper position-relative'>
                                {isSeller && <p className='shipping-info m-0 position-absolute text-nowrap end-0'>Shipping cost not included.</p>}
                            </div>
                        </div>
                        {isSeller && (<>
                        <div className='amount-wrapper' style={{ width: `${isSeller ? columnWidths.platformFeesWidth : columnWidths.totalPriceWidth}px`, minWidth: 100, }}>
                            (<CurrencyFormatter
                                amount={(orderSummary.platformFees)}
                                locale="en-US"
                                currency="USD"
                            />)
                        </div>
                        <div className='amount-wrapper' style={{ width: `${isSeller ? columnWidths.platformFeesWidth : columnWidths.totalPriceWidth}px`, minWidth: 100, }}>
                        (<CurrencyFormatter
                            amount={(orderSummary.totalPlatformFees)}
                            locale="en-US"
                            currency="USD"
                        />)
                        </div>
                        </>)}
                        {!isSeller && <><div className='amount-wrapper' style={{ width: `${columnWidths.handlingWidth}px` }}>
                            <CurrencyFormatter
                                amount={orderSummary.totalHandlingFees}
                                locale="en-US"
                                currency="USD"
                            />
                        </div>
                            <div className='amount-wrapper' style={{ width: `${columnWidths.servicesWidth}px` }}>
                                <CurrencyFormatter
                                    amount={orderSummary.totalServiceFees}
                                    locale="en-US"
                                    currency="USD"
                                />
                            </div></>}
                    </div>
                <div className="total--amount-wrapper d-flex justify-content-end pb-4" style={{ width: `${columnWidths.ownerStatusWidth + columnWidths.actionWidth}px`, minWidth: 180 }}>
                    {!isSeller && (<><div style={{ width: (!isSeller && !CommonService.isAdmin ) && (`${columnWidths.handlingWidth}px`) }}>Total:</div>
                        <div style={{ width: (!isSeller && !CommonService.isAdmin ) && (`${columnWidths.servicesWidth}px` )}} className='position-relative ms-2'>
                                <CurrencyFormatter
                                    amount={!isSeller ? orderSummary.orderTotal : orderSummary.orderTotalWithoutPlatformFees}
                                    locale="en-US"
                                    currency="USD"
                                />
                                <p className='shipping-info m-0 position-absolute text-nowrap end-0'>Shipping cost not included.</p>
                        </div> 
                    </>)}
                    {/* <p className='shipping-info m-0'>Shipping cost not included.</p> */}
                </div>
            </div>
        </div>)}
        {viewDetails &&
            <ViewProductDetail handleViewClose={handleViewClose} inventoryId={inventoryId} />
        }
        {viewBarrelSelection && <BarrelSelectionPanel 
                                    requestType={Constant.RequestType.ORDER_REQUEST} 
                                    requestId={id} 
                                    groupId={selectedOrderItem?.groupId} 
                                    orderItemId={selectedOrderItem?.id}
                                    inventoryId={selectedOrderItem?.inventoryId} 
                                    inventoryName={selectedOrderItem?.inventoryName} 
                                    requiredQuantity={selectedOrderItem?.quantity} 
                                    companyName={selectedOrderItem?.inventoryOwnerCompanyName} 
                                    currentStatus={selectedOrderItem?.orderStatus} 
                                    handleCloseBarrelSelection={handleCloseBarrelSelection} />}
        </>
    );
};

export default InventoryFileListTable;